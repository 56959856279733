import { defineStore } from 'pinia'

interface UserInfoState {
    permissions: Set<string>;
    fetched: boolean;
    loading: boolean
}

export const useUserInfo = defineStore('userInfoStore', {
  state: (): UserInfoState => ({
    permissions: new Set<string>(),
    fetched: false,
    loading: false
  }),
  actions: {
    async fetch () {
      try {
        if (import.meta.server) { return }

        this.loading = true
        const { permissions } = await $fetch<{ permissions: string[] }>('/api/user', {
          method: 'GET'
        })
        this.permissions = new Set(permissions)
        this.fetched = true
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      } finally {
        this.loading = false
      }
    },
    hasPermission (requiredPermissions: string[]) {
      return requiredPermissions.every(permission => this.permissions.has(permission))
    }
  }
})
